import { DateTime } from 'luxon';
import { FC } from 'react';

import styles from './ArticleDate.module.scss';

interface Props {
  publishedAt: string;
}

const ArticleDate: FC<Props> = ({ publishedAt }) => {
  const relativeTime = DateTime.fromISO(publishedAt).toRelative();

  return (
    <div className={styles.block}>
      <p className={styles.title}>{relativeTime}</p>
    </div>
  );
};

export default ArticleDate;
