import classNames from 'classnames';
import { motion } from 'framer-motion';
import { FC, useState } from 'react';
import { ArticleHighlight } from '~/types';

import styles from './ArticleListHighlights.module.scss';

interface Props {
  list: ArticleHighlight[];
}

const ArticleListHighlights: FC<Props> = ({ list }) => {
  const sortedList = list.sort((a, b) => a.order - b.order);

  const [showAll, setShowAll] = useState(false);

  return (
    <div>
      <ul className={styles.list}>
        {sortedList.slice(0, 1).map(({ title }, index) => (
          <li
            key={index}
            className={styles.listItem}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        ))}
        <motion.li
          initial={{ height: 0 }}
          animate={{ height: showAll ? 'auto' : 0 }}
          transition={{ duration: 0.2, ease: 'easeOut' }}
        >
          <ul>
            {sortedList.slice(1, undefined).map(({ title }, index) => (
              <li
                key={index + 2}
                className={styles.listItem}
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            ))}
          </ul>
        </motion.li>
      </ul>
      {sortedList.length > 2 && (
        <div className={styles.toggleButtonHolder}>
          <div
            className={styles.fade}
            style={{ display: showAll ? 'none' : 'block' }}
          ></div>
          <button
            onClick={() => setShowAll((prevShowAll) => !prevShowAll)}
            className={classNames(styles.toggleButton, {
              [styles.opened]: showAll,
            })}
          >
            {showAll ? 'See Less' : 'See More'}
          </button>
        </div>
      )}
    </div>
  );
};

export default ArticleListHighlights;
